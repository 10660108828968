import { request } from '@/api'

export type CDSTimespan = [number, number]

export type CDSData = Record<string, number>

export interface ScanStatus {
  scan_id: string
  initiated: number
  started: number
  completed: number
}

const PATH = 'caster-data-server'

export function getTimespans (plant: string): Promise<CDSTimespan[] | null> {
  // TODO: translate message
  return request('get', `${PATH}/timespans/${plant}`, 'Failed to get timespans')
}

export function getData (plant: string, timestamp: number): Promise<CDSData | null> {
  // TODO: translate message
  return request('get', `${PATH}/data/${plant}/${timestamp}`, 'Failed to get data')
}

export function getAllScanStatus (plant: string): Promise<ScanStatus[] | null> {
  return request('get', `${PATH}/scanstatus/${plant}`, 'Failed to get all scan status')
}

export function getScanStatus (plant: string, scanId: string): Promise<ScanStatus | null> {
  return request('get', `${PATH}/scanstatus/${plant}/${scanId}`, 'Failed to get scan status')
}
