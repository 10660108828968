import cloneDeep from 'lodash/cloneDeep'

import { getDefaultElement } from '@/store/elements/logic/index'
import { DEFINITION, DefinitionKey } from '@/store/type/consts'
import type {
  DataPointMountLogMapKey,
  ElementMaps,
  ElementName,
  MountLogMapKey,
  SensorPointMountLogMapKey,
  SideDependentElementName,
  SlotMapKey,
  TagName,
} from '@/types/state'
import { ElementMapsUtil } from '@/Util/ElementMapsUtil'
import { Mapping } from '@/Util/mapping/Mapping'

export function handleDirtyDeletePaths (dirtyDeletePaths: string[], paths: string[]) {
  // remove path from list if already in list
  // add to list otherwise
  paths.forEach((path) => {
    const index = dirtyDeletePaths.indexOf(path)

    if (~index) {
      dirtyDeletePaths.splice(index, 1)
    }
    else {
      dirtyDeletePaths.push(path)
    }
  })
}

export function handleUndoChanges (editElements: any, action: any, editElementsInitial: any) {
  for (const path in editElements) {
    if (path.includes(action.elementType)) {
      if (action.key) {
        editElements[path][action.key] = editElementsInitial[path][action.key]
      }
      else {
        editElements[path] = { ...editElementsInitial[path] }
      }
    }
  }
}

function updateSetWithSelectedElement (
  selectedPaths: Set<string>,
  element: string,
  massSelect: boolean,
  multiSelect: boolean,
) {
  const found = selectedPaths.has(element)
  const length = selectedPaths.size

  if (massSelect) {
    if (found) {
      selectedPaths.clear()

      selectedPaths.add(element)
    }
    else {
      if (length < 1) {
        selectedPaths.add(element)
      }
      else {
        const { done, value } = selectedPaths.values().next()
        const firstElement = !done ? value : ''

        const cutFirst = firstElement.length - firstElement.lastIndexOf(':') - 1 // TODO: does this work with ids?
        const cutLast = element.length - element.lastIndexOf(':') - 1

        const first = Number(firstElement.substr(firstElement.length - cutFirst))
        const last = Number(element.substr(element.length - cutLast))

        const min = Math.min(last, first)
        const max = Math.max(last, first)

        for (let i = min; i <= max; i++) {
          selectedPaths.add(element.slice(0, -cutLast) + i)
        }
      }
    }
  }
  else {
    if (!multiSelect) {
      selectedPaths.clear()

      if (length > 1 || !found) {
        selectedPaths.add(element)
      }
    }
    else {
      if (found) {
        selectedPaths.delete(element)
      }
      else {
        selectedPaths.add(element)
      }
    }
  }
}

export function addPathsIfNotAlreadyInList (list: string[], paths: string[]) {
  paths.forEach(path => {
    if (!list.includes(path)) {
      list.push(path)
    }
  })
}

export function updateSetWithSelectedData (
  selectedPaths: Set<string>,
  selectedData: string[],
  massSelect = false,
  multiSelect = false,
) {
  selectedData.forEach(element => {
    updateSetWithSelectedElement(selectedPaths, element, massSelect, multiSelect)
  })
}

export function ensureActionPathIsArray (action: any): string[] {
  return action.path instanceof Array ? action.path : [ action.path ]
}

export function setEditValuesForEachType (stateEditValues: any, action: any, types: DefinitionKey[]) {
  let editValues = {
    ...stateEditValues,
  }

  types.forEach(type => {
    Object.keys(DEFINITION[type].fields).forEach(field => {
      editValues = {
        ...editValues,
        [type]: {
          ...editValues[type],
          ...action.editValues[type], // TODO: this may override [field] from a previous iteration of the inner loop
          [field]: action.editValues[type][field],
        },
      }
    })
  })

  return editValues
}

// TODO: the first string should be CasterElementNames but this would require us to add all keys of CasterElementNames
const ElementTypePathMap: Readonly<Record<TagName, string | null>> = {
  Nozzle: 'nozzles',
  Roller: 'rollers',
  RollerBearing: 'roller-bearings',
  RollerBody: 'roller-bodies',
  Segment: 'segments',
  SegmentGroup: 'segment-groups',
  SupportPoint: 'support-points',
  SensorPoint: 'sensor-points',
  DataLine: 'data-lines',
  CoolingLoop: 'cooling-loops',
  DataPoint: 'data-points',
  MoldFace: null,
  AirLoop: null,
  CoolingZone: null,
  LoopAssignment: null,
  Mold: null,
  MoldBCArea: null,
  Passline: null,
  PasslineSection: null,
  StrandGuide: null,
}

export function getElementTypeURL (elementType: TagName) {
  if (!ElementTypePathMap[elementType]) {
    throw new Error(`Invalid element type: ${elementType}`)
  }

  return ElementTypePathMap[elementType]
}

const ParentMountLogMap: Readonly<Record<string, { key: string, mountLogType: string, type: string }>> = {
  Roller: { key: 'segmentMountLogId', mountLogType: 'SegmentMountLog', type: 'Segment' },
  RollerBody: { key: 'rollerMountLogId', mountLogType: 'RollerMountLog', type: 'Roller' },
  RollerBearing: { key: 'rollerMountLogId', mountLogType: 'RollerMountLog', type: 'Roller' },
  Nozzle: { key: 'segmentMountLogId', mountLogType: 'SegmentMountLog', type: 'Segment' },
  SupportPoint: { key: 'segmentGroupMountLogId', mountLogType: 'SegmentGroupMountLog', type: 'SegmentGroup' },
  RollerBodySensorPoint: { key: 'rollerBodyMountLogId', mountLogType: 'RollerBodyMountLog', type: 'RollerBody' },
  RollerSensorPoint: { key: 'rollerMountLogId', mountLogType: 'RollerMountLog', type: 'Roller' },
  SegmentSensorPoint: { key: 'segmentMountLogId', mountLogType: 'SegmentMountLog', type: 'Segment' },
}

export function handleNewMountLog (
  newElementMaps: ElementMaps,
  type: ElementName,
  mountLogType: Exclude<MountLogMapKey, DataPointMountLogMapKey | SensorPointMountLogMapKey>,
  newMountLog: ElementMaps[typeof mountLogType][string],
  oldId?: string | null,
) {
  const oldMountLog = oldId ? newElementMaps[mountLogType][oldId] : null

  newElementMaps[mountLogType][newMountLog.id] = {
    ...newElementMaps[mountLogType][newMountLog.id],
    ...newMountLog,
  }

  if (oldMountLog && oldId) {
    delete newElementMaps[mountLogType][oldId]
  }

  const parentMountLogKey = ParentMountLogMap[type]?.key
  const parentMountLogId = (newMountLog as any)[parentMountLogKey ?? ''] as string | undefined
  // eslint-disable-next-line max-len
  const parentMountLog = (newElementMaps as any)[ParentMountLogMap[type]?.mountLogType ?? '']?.[parentMountLogId ?? '']

  if (parentMountLog) {
    // first letter to lowercase
    const lowerCasedType = type[0]?.toLowerCase() + type.substring(1)
    const isSensorPointType = lowerCasedType.includes('SensorPoint')
    const typeKey = isSensorPointType ? 'sensorPointMountLogs' : `${lowerCasedType}MountLogs`

    if (!parentMountLog[typeKey]) {
      parentMountLog[typeKey] = []
    }

    parentMountLog[typeKey].push(newMountLog.id)

    if (oldId) {
      // delete the old one
      const oldIndex = parentMountLog[typeKey].findIndex((mountLogId: string) => mountLogId === oldId)

      if (~oldIndex) {
        parentMountLog[typeKey].splice(oldIndex, 1)
      }
    }
  }

  const tagName = ElementMapsUtil.getTagName(type)

  if (Mapping.mountLogIdByTypeAndNumericId[tagName]) {
    const numericId = oldId
      ? Mapping.numericIdByMountLogId[oldId]
      // FIXME: this is a workaround because we neither have a separate map for numeric ids nor do we have a max/count
      // eslint-disable-next-line max-len
      : Math.max(...Object.keys(Mapping.mountLogIdByTypeAndNumericId[tagName]).map(Number)) + 1

    if (numericId === undefined || Number.isNaN(numericId)) {
      return null
    }

    if (oldId) {
      delete Mapping.numericIdByMountLogId[oldId]
    }

    const fullPath = oldId
      ? Mapping.elementPathByMountLogId[oldId]
      // FIXME: we need a function for this!
      // eslint-disable-next-line max-len
      : `${Mapping.elementPathByMountLogId[parentMountLogId ?? '']}/${tagName}:${numericId}`

    if (oldId) {
      delete Mapping.elementPathByMountLogId[oldId]
    }

    if (newMountLog.id) {
      Mapping.numericIdByMountLogId[newMountLog.id] = numericId

      Mapping.mountLogIdByTypeAndNumericId[tagName][numericId] = newMountLog.id

      Mapping.elementPathByMountLogId[newMountLog.id] = fullPath ?? ''

      Mapping.mountLogIdByElementPath[fullPath ?? ''] = newMountLog.id
    }

    return fullPath
  }

  return null
}

export function handleUpdateResponse (
  response: ElementMaps, // TODO: this type needs to be changed it is not ElementMaps but an object that looks similar
  newElementMaps: ElementMaps,
  dirtyPaths: string[],
) {
  for (const key in response) {
    if (key.includes('MountLog')) {
      continue
    }

    const elementName = key.replace('Slot', '') as ElementName
    const mountLogType = `${elementName}MountLog` as MountLogMapKey
    const slotType = `${elementName}Slot` as SlotMapKey
    const oldNewMountLogMap = response[mountLogType] ?? {}
    const newSlot = response[slotType] ?? {}

    // handle slots
    Object.keys(newSlot).forEach(id => {
      newElementMaps[slotType][id] = newSlot[id]!
    })

    Object.entries(oldNewMountLogMap).forEach(([ oldId, newMountLog ]: any[]) => {
      const isUpdate = oldId !== newMountLog.id

      const path = oldId ? Mapping.elementPathByMountLogId[oldId] : Mapping.elementPathByMountLogId[newMountLog.id]

      const newPath = handleNewMountLog(
        newElementMaps,
        elementName,
        mountLogType as any,
        newMountLog,
        isUpdate ? oldId : null,
      )

      // TODO: refactor this
      if (path || (!isUpdate && newPath)) {
        dirtyPaths.push(path ?? newPath ?? '')
      }
    })
  }
}

const sideDependentElements: SideDependentElementName[] = [
  'Nozzle',
  'Roller',
  'RollerBearing',
  'RollerBody',
  'RollerBodyDataPoint',
  'RollerBodySensorPoint',
  'RollerDataPoint',
  'RollerSensorPoint',
  'Segment',
  'SegmentDataPoint',
  'SegmentSensorPoint',
]

export function prepareNewElements<Slot extends BaseSlot, MountLog extends BaseMountLog> (
  addedElements: FullCasterElement<Slot, MountLog>[],
  elementName: ElementName,
  elementMaps: ElementMaps,
) {
  const tagName = ElementMapsUtil.getTagName(elementName)

  return addedElements.map(element => {
    let newElement = cloneDeep(element) as FullCasterElement<Slot, MountLog> & {
      side?: StrandSide | null
      parentPath?: string
    }

    if (sideDependentElements.includes(elementName as SideDependentElementName)) {
      const mountLogId = Mapping.mountLogIdByTypeAndNumericId[tagName][element.id]
      const path = Mapping.elementPathByMountLogId[mountLogId ?? '']
      // e.g. 'SegmentGroup:0/Segment:0/Roller:0/RollerBody:0/SensorPoint:0' -> 'SegmentGroup:0/Segment:0'
      let parentSlot: BaseSideSlot | null = null

      if (path) {
        const segmentPath = path.replace(/(SegmentGroup:\d+\/Segment:\d+).*/, '$1') ?? ''

        parentSlot = ElementMapsUtil.getSlotByPath<SegmentSlot>(segmentPath, elementMaps)
      }
      else { // completely new elements do not have a path
        const { key, type, mountLogType } = ParentMountLogMap[elementName] ?? {}

        const parentMountLog = elementMaps[mountLogType as MountLogMapKey]
          ?.[element[key as keyof typeof element] as string ?? '']

        parentSlot = elementMaps[`${type}Slot` as SlotMapKey][parentMountLog?.slotId ?? ''] as BaseSideSlot | null ??
          null
      }

      if (!parentSlot) {
        // eslint-disable-next-line no-console
        console.error('No segment mount log found for path:', path)
      }

      if (parentSlot?.side) {
        newElement.side = parentSlot?.side
      }
    }

    newElement = getDefaultElement(newElement, tagName)

    // TODO: check if parentPath is still used as it is not present in FullCasterElement
    if (Object.keys(newElement).includes('parentPath')) {
      delete newElement.parentPath
    }

    return newElement
  })
}
