import { ExecutionStepState } from '@/react/dialogs/executables/enums'
import { positivePropertiesAndInKeyList } from '@/store/elements/logic/index'
import type { DataState, LoopCounter } from '@/types/data'
import type { ElementMaps } from '@/types/state'
import { ElementMapsUtil } from '@/Util/ElementMapsUtil'

import { DataActionsEnum, initialState } from '../consts'
import {
  addPathsIfNotAlreadyInList,
  ensureActionPathIsArray,
  handleDirtyDeletePaths,
  handleUndoChanges,
  setEditValuesForEachType,
  updateSetWithSelectedData,
} from '../logic'
import { AllChildrenSelected } from '../logic/AllChildrenSelected'
import { AllPaths } from '../logic/AllPaths'

const editionReducers = {
  [DataActionsEnum.ACTION_RESET_CHANGES]: (state: DataState) => ({
    ...state,
    hasChanges: initialState.hasChanges,
    hasEditChanges: initialState.hasEditChanges,
  }),

  [DataActionsEnum.ACTION_RESET_ALL]: (
    state: DataState,
    { skipExecutables, skipCatalog }: Record<string, boolean>,
  ) => ({
    ...initialState,
    executableDefinitions: skipExecutables ? state.executableDefinitions : initialState.executableDefinitions,
    catalog: skipCatalog ? state.catalog : initialState.catalog,
  }),

  [DataActionsEnum.ACTION_SAVE_ELEMENT_REQUEST]: (state: DataState) => ({
    ...state,
  }),

  [DataActionsEnum.ACTION_SAVE_ELEMENT_ERROR]: (state: DataState, error: any) => {
    // eslint-disable-next-line no-console
    console.error('error', error)

    return {
      ...state,
    }
  },

  [DataActionsEnum.ACTION_SAVE_ELEMENT_SUCCESS]: (
    state: DataState,
    { result }: any,
  ) => {
    // if result is null, the state should not be updated
    if (!result) {
      return state
    }

    return {
      ...state,
      dirtyPaths: result.dirtyPaths,
      dirtyDeletePaths: result.dirtyDeletePaths,
      hidePaths: result.hidePaths,
      editElements: result.editElements,
      // only update editElementsInitial for paths that are in the result.paths
      editElementsInitial: {
        ...state.editElementsInitial,
        ...Object
          .keys(result.editElements)
          .filter((path) => result.paths.includes(path))
          .reduce((acc, path) => {
            acc[path] = result.editElements[path]

            return acc
          }, {} as Record<string, any>),
      },
      selectedPaths: result.selectedPaths,
      allPaths: AllPaths.generate(result.elementMaps),
      loopCounter: result.loopCounter,
      hasChanges: true,
      loading: false,
    }
  },

  [DataActionsEnum.ACTION_CREATE_ELEMENT_REQUEST]: (state: DataState) => ({
    ...state,
  }),

  [DataActionsEnum.ACTION_CREATE_ELEMENT_ERROR]: (state: DataState) => ({
    ...state,
  }),

  [DataActionsEnum.ACTION_CREATE_ELEMENT_SUCCESS]: (
    state: DataState,
    action: any,
  ) => ({
    ...state,
    ...action.result,
    allPaths: AllPaths.generate(action.result.elementMaps),
  }),

  [DataActionsEnum.ACTION_SAVE_CATALOG]: (state: DataState, action: any) => {
    if (!action.catalogId) {
      return {
        ...state,
        catalog: action.catalog,
      }
    }

    return {
      ...state,
      catalog: action.catalog,
      currentCatalogId: action.catalogId,
    }
  },

  [DataActionsEnum.ACTION_SET_CATALOG_ELEMENT]: (state: DataState, action: any) => ({
    ...state,
    catalogElement: action.element ? action.element : initialState.catalogElement,
  }),

  [DataActionsEnum.ACTION_EDIT_CHANGES]: (state: DataState, action: any) => ({
    ...state,
    hasEditChanges: {
      ...state.hasEditChanges,
      [action.elementType]: action.hasEditChanges,
    },
  }),

  [DataActionsEnum.ACTION_VALIDATION_INPUT]: (state: DataState, action: any) => {
    const { editValues, parentPath } = state
    let createValid = false

    if (editValues && (editValues as any)[action.elementType]) {
      const keys = Object.keys((editValues as any)[action.elementType])

      createValid = positivePropertiesAndInKeyList(editValues, keys, action.elementType)
    }

    if (Object.keys(parentPath).length <= 1) {
      createValid = false
    }

    return {
      ...state,
      createValid: {
        ...state.createValid,
        [action.elementType]: createValid,
      },
    }
  },
  [DataActionsEnum.ACTION_MERGE_NEW_REQUEST]: (state: DataState) => ({
    ...state,
  }),

  [DataActionsEnum.ACTION_MERGE_NEW_ERROR]: (state: DataState) => ({
    ...state,
  }),

  [DataActionsEnum.ACTION_MERGE_NEW_SUCCESS]: (state: DataState, { result: { loopCounter } }: any) => ({
    ...state,
    dirtyPaths: [],
    dirtyDeletePaths: [],
    hidePaths: [],
    hasChanges: true,
    loopCounter,
  }),

  [DataActionsEnum.ACTION_SET_CURRENT_CATALOG_ID]: (state: DataState, { currentCatalogId }: any) => {
    if (!currentCatalogId) {
      return {
        ...state,
        catalogElement: initialState.catalogElement,
        catalog: initialState.catalog,
        currentCatalogId,
      }
    }

    return {
      ...state,
      currentCatalogId,
    }
  },

  [DataActionsEnum.ACTION_SET_CATALOG_LIST]: (state: DataState, { catalogList }: any) => ({
    ...state,
    catalogList,
  }),

  [DataActionsEnum.ACTION_SET_UPDATE_NOTIFICATIONS]: (state: DataState, { updatesCount, update, clear }: any) => ({
    ...state,
    updatesCount: clear ? 0 : state.updatesCount + updatesCount,
    updates: clear ? [] : [ ...state.updates, update ],
  }),

  [DataActionsEnum.ACTION_APPLY_UPDATES]: (state: DataState) => ({
    ...state,
    updatesCount: 0,
    updates: [],
  }),

  [DataActionsEnum.ACTION_SET_LIVE_MODE]: (state: DataState, { liveMode }: { liveMode?: boolean }) => ({
    ...state,
    liveMode: liveMode ?? !state.liveMode,
  }),
  [DataActionsEnum.ACTION_ADD_DIRTY_PATH]: (state: DataState, action: any) => {
    const paths = ensureActionPathIsArray(action)
    const dirtyPaths = [ ...state.dirtyPaths ]

    addPathsIfNotAlreadyInList(dirtyPaths, paths)

    return {
      ...state,
      dirtyPaths,
    }
  },

  [DataActionsEnum.ACTION_CLEAR_DIRTY_PATHS]: (state: DataState) => ({
    ...state,
    dirtyPaths: [],
  }),

  [DataActionsEnum.ACTION_SELECT_EDIT_DATA]: (
    state: DataState,
    { selectedData, massSelect, multiSelect, clearFirst, elementMaps }: {
      selectedData: string[] | null
      massSelect?: boolean
      multiSelect?: boolean
      clearFirst?: boolean
      elementMaps: ElementMaps
    },
  ) => {
    // reset
    if (!selectedData) {
      return {
        ...state,
        editElements: {},
        editElementsInitial: {},
        selectedPaths: new Set<string>(initialState.selectedPaths),
        allPaths: AllPaths.generate(elementMaps),
        allChildrenSelectedPaths: new Set<string>(initialState.allChildrenSelectedPaths),
      }
    }

    const selectedPaths = clearFirst ? new Set<string>() : new Set<string>(state.selectedPaths)

    updateSetWithSelectedData(selectedPaths, selectedData, massSelect, multiSelect)

    const allChildrenSelectedPaths = AllChildrenSelected.generate(selectedPaths, elementMaps)

    return {
      ...state,
      selectedPaths,
      allPaths: AllPaths.generate(elementMaps),
      allChildrenSelectedPaths,
      editElements: Object
        .keys(state.editElements ?? {})
        .filter(key => selectedPaths.has(key))
        .reduce((acc, key) => {
          acc[key] = state.editElements?.[key]

          return acc
        }, {} as Record<string, any>),
    }
  },

  [DataActionsEnum.ACTION_SET_ELEMENTS]: (state: DataState, action: any) => {
    if (action.element) {
      if (action.path instanceof Array) {
        const elements: any = {}

        action.path.forEach((path: string, i: number) => {
          elements[path] = {
            ...state.editElements[path],
            ...(action.element instanceof Array ? action.element[i] : action.element),
          }
        })

        return {
          ...state,
          editElements: {
            ...state.editElements,
            ...elements,
          },
        }
      }

      return {
        ...state,
        editElements: {
          ...state.editElements,
          [action.path]: {
            ...state.editElements[action.path],
            ...action.element,
          },
        },
      }
    }

    const paths = ensureActionPathIsArray(action)

    const elements: any = {}

    paths.forEach((path: string) => {
      // force is used to force the update of a selected element
      if ((!state.editElements[path] || action.force)) {
        elements[path] = ElementMapsUtil.getFullCasterElementByPath(path, action.elementMaps, true)
      }
    })

    if (Object.keys(elements).length > 0) {
      return {
        ...state,
        editElements: {
          ...state.editElements,
          ...elements,
        },
        editElementsInitial: {
          ...state.editElementsInitial,
          ...elements,
        },
      }
    }

    if (!action.element && !action.path) {
      return {
        ...state,
        editElements: {},
      }
    }

    return {
      ...state,
    }
  },

  [DataActionsEnum.ACTION_SET_PARENT_PATH]: (state: DataState, action: any) => ({
    ...state,
    parentPath: action.parentPath,
  }),

  [DataActionsEnum.ACTION_SET_NEW_ELEMENTS_TO_DRAW]: (state: DataState, { newCopiedElementsToDraw }: any) => ({
    ...state,
    newCopiedElementsToDraw,
  }),

  [DataActionsEnum.ACTION_ADD_DIRTY_DELETE_PATH]: (state: DataState, action: any) => {
    const dirtyDeletePaths = [ ...state.dirtyDeletePaths ]
    const paths = ensureActionPathIsArray(action)

    handleDirtyDeletePaths(dirtyDeletePaths, paths)

    return {
      ...state,
      dirtyDeletePaths,
      hasChanges: true,
    }
  },

  [DataActionsEnum.ACTION_ADD_PENDING_DELETE_LIST]: (state: DataState, action: any) => ({
    ...state,
    pendingDeleteList: action.path,
  }),

  [DataActionsEnum.ACTION_CLEAR_DIRTY_DELETE_PATHS]: (state: DataState) => ({
    ...state,
    dirtyDeletePaths: initialState.dirtyDeletePaths,
    hidePaths: initialState.hidePaths,
  }),

  [DataActionsEnum.ACTION_SET_ACTIVE_EDIT_TAB]: (state: DataState, action: any) => ({
    ...state,
    activeEditTab: action.activeEditTab,
  }),

  [DataActionsEnum.ACTION_EDIT_VALUES]: (state: DataState, action: any) => {
    const types = Object.keys(action.editValues)

    const editValues = setEditValuesForEachType({ ...state.editValues }, action, types as any)

    return {
      ...state,
      editValues,
    }
  },

  [DataActionsEnum.ACTION_UNDO_ELEMENT_CHANGES]: (state: DataState, action: any) => {
    const editElements = { ...state.editElements }
    const editElementsInitial = { ...state.editElementsInitial }

    handleUndoChanges(editElements, action, editElementsInitial)

    return {
      ...state,
      editElements,
    }
  },

  [DataActionsEnum.ACTION_OVERWRITE_ALL_SUCCESS]: (
    state: DataState,
    { loopCounter }: { loopCounter: LoopCounter },
  ) => {
    if (loopCounter) {
      return {
        ...state,
      }
    }

    return {
      ...state,
      dirtyPaths: [],
      dirtyDeletePaths: [],
      hidePaths: [],
      hasChanges: true,
      loopCounter,
    }
  },
  [DataActionsEnum.ACTION_APPLY_CHANGES_SUCCESS]: (
    state: DataState,
    {
      dirtyPaths,
      hidePaths,
      dirtyDeletePaths,
    }: any,
  ) => ({
    ...state,
    dirtyPaths,
    hidePaths,
    dirtyDeletePaths,
    hasChanges: true,
  }),

  [DataActionsEnum.ACTION_REMOVE_DELETE_PATHS]: (state: DataState, { paths }: any) => ({
    ...state,
    dirtyDeletePaths: state.dirtyDeletePaths.filter(path => !paths.includes(path)),
  }),

  [DataActionsEnum.ACTION_GET_EXECUTABLE_DEFINITIONS_SUCCESS]: (state: DataState, action: any) => ({
    ...state,
    executableDefinitions: action.result.executableDefinitions,
  }),

  [DataActionsEnum.ACTION_SET_EXECUTION_STATE]: (state: DataState, action: any) => {
    const key = `${action.simulationCaseId}_${action.definitionId}_${action.caseId}${
      action.stepId ? `_${action.stepId}` : ''
    }`

    return {
      ...state,
      executionStateDictionary: {
        ...state.executionStateDictionary,
        [key]: action.state,
      },
    }
  },

  [DataActionsEnum.ACTION_SET_EXECUTION_DATA]: (state: DataState, { execution }: any) => ({
    ...state,
    execution,
  }),

  [DataActionsEnum.ACTION_SET_MANY_EXECUTION_STATES]: (
    state: DataState,
    { newValues }: { newValues: Record<string, string> },
  ) => {
    const executionStateCopy = { ...state.executionStateDictionary }

    Object.entries(newValues).forEach(([ key, value ]) => {
      if (value === 'delete') {
        delete executionStateCopy[key]

        return
      }

      executionStateCopy[key] = value as ExecutionStepState
    })

    return {
      ...state,
      executionStateDictionary: executionStateCopy,
    }
  },
}

export default editionReducers
