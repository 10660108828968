import cloneDeep from 'lodash/cloneDeep'
import memoize from 'lodash/memoize'

import { AppState } from '@/store/application/main/consts'
import type { CasterElementNames } from '@/types/data'
import type { DefaultState } from '@/types/state'

import * as flags from './consts'
import FeatureFlagsUtil from './util'

export type Permissions = Record<string, boolean>

export default class FeatureFlags {
  public static getRealFeatureFlags = memoize((state: DefaultState): Permissions => {
    const { application: { main: { authenticationData, currentSimulationCase } } } = state
    const isCaseOwner = currentSimulationCase && currentSimulationCase.userId === authenticationData?.id
    let featureFlags = authenticationData?.featureFlags ?? {}

    featureFlags = cloneDeep(featureFlags ?? {})

    if (currentSimulationCase?.simulationStartedAt || currentSimulationCase?.blueprintId || !isCaseOwner) {
      featureFlags[flags.Caster_Details_Edit_Roller] = false
      featureFlags[flags.Caster_Details_Edit_RollerBearing] = false
      featureFlags[flags.Caster_Details_Edit_RollerBody] = false
      featureFlags[flags.Caster_Details_Edit_Mold] = false
      featureFlags[flags.Caster_Details_Edit_Nozzle] = false
      featureFlags[flags.Caster_Details_Edit_DataLine] = false
      featureFlags[flags.Caster_Details_Edit_DataPoint] = false
      featureFlags[flags.Caster_Details_Edit_SensorPointInMoldFace] = false
      featureFlags[flags.Caster_Details_Edit_SensorPointInNozzle] = false
      featureFlags[flags.Caster_Details_Edit_SensorPointInRoller] = false
      featureFlags[flags.Caster_Details_Edit_SensorPointInRollerBearing] = false
      featureFlags[flags.Caster_Details_Edit_SensorPointInRollerBody] = false
      featureFlags[flags.Caster_Details_Edit_SensorPointInSegment] = false
      featureFlags[flags.Caster_Details_Edit_CoolingLoop] = false
      featureFlags[flags.Caster_Details_Edit_Segment] = false
      featureFlags[flags.Caster_Details_Edit_SupportPoint] = false
      featureFlags[flags.ProjectData__Upload_NozzleCatalog] = false
      featureFlags[flags.ProjectData__Use_GenerateCaster] = false
    }

    return featureFlags
  }, (state) =>
    JSON.stringify({
      authenticationData: state.application.main.authenticationData,
      currentSimulationCase: state.application.main.currentSimulationCase,
    }))

  public static isLoggedIn ({ application: { main: { authenticationData } } }: DefaultState): boolean {
    return Object.keys(authenticationData?.featureFlags ?? {}).length > 0
  }

  public static canViewNozzlesIn3D (permissions: Permissions = {}): boolean {
    return permissions[flags.ThreeD_Caster_Display_Nozzle] ?? false
  }

  public static canSetDefaultCase (permissions: Permissions = {}): boolean {
    return permissions[flags.General_Set_Default_Case] ?? false
  }

  public static canViewRollersIn3D (permissions: Permissions = {}): boolean {
    return permissions[flags.ThreeD_Caster_Display_Roller] ?? false
  }

  public static canViewSensorPointsInRollerBodiesIn3D (permissions: Permissions = {}): boolean {
    return permissions[flags.ThreeD_Caster_Display_SensorPointInRollerBody] ?? false
  }

  public static canViewSupportPointsIn3D (permissions: Permissions = {}): boolean {
    return permissions[flags.ThreeD_Caster_Display_SupportPoint] ?? false
  }

  public static canEditElement (elementType: string, permissions: Permissions = {}): boolean {
    switch (elementType) {
      case 'General':
        return this.canEditMold(permissions)
      case 'Nozzle':
        return this.canEditNozzle(permissions)
      case 'Roller':
        return this.canEditRoller(permissions)
      case 'RollerBody':
        return this.canEditRollerBody(permissions)
      case 'RollerBearing':
        return this.canEditRollerBearing(permissions)
      case 'SensorPoint':
        return (
          FeatureFlags.canEditSensorPointInMoldFace(permissions) ||
          FeatureFlags.canEditSensorPointInRollerBody(permissions) ||
          FeatureFlags.canEditSensorPointInRollerBearing(permissions) ||
          FeatureFlags.canEditSensorPointInRoller(permissions) ||
          FeatureFlags.canEditSensorPointInSegment(permissions) ||
          FeatureFlags.canEditSensorPointInNozzle(permissions)
        )
      case 'DataPoint':
        return this.canEditDataPoint(permissions)
      case 'DataLine':
        return this.canEditDataLine(permissions)
      case 'Segment':
        return this.canEditSegment(permissions)
      case 'SegmentGroup':
        return this.canEditSegment(permissions) // TODO: add canEditSegmentGroup
      case 'SegmentGroupSupportPoints':
      case 'SupportPoint':
        return this.canEditSupportPoint(permissions)
      case 'Elements':
      case 'Modules':
        return false
      default:
        // eslint-disable-next-line no-console
        console.log(`Unknown element type: ${elementType}`)

        return false
    }
  }

  public static canEditMold (permissions: Permissions = {}): boolean {
    return permissions[flags.Caster_Details_Edit_Mold] ?? false
  }

  public static canEditRoller (permissions: Permissions = {}): boolean {
    return permissions[flags.Caster_Details_Edit_Roller] ?? false
  }

  public static canEditRollerBody (permissions: Permissions = {}): boolean {
    return permissions[flags.Caster_Details_Edit_RollerBody] ?? false
  }

  public static canEditRollerBearing (permissions: Permissions = {}): boolean {
    return permissions[flags.Caster_Details_Edit_RollerBearing] ?? false
  }

  public static canEditSensorPointInMoldFace (permissions: Permissions = {}): boolean {
    return permissions[flags.Caster_Details_Edit_SensorPointInMoldFace] ?? false
  }

  public static canEditSensorPointInRollerBody (permissions: Permissions = {}): boolean {
    return permissions[flags.Caster_Details_Edit_SensorPointInRollerBody] ?? false
  }

  public static canEditSensorPointInRollerBearing (permissions: Permissions = {}): boolean {
    return permissions[flags.Caster_Details_Edit_SensorPointInRollerBearing] ?? false
  }

  public static canEditSensorPointInRoller (permissions: Permissions = {}): boolean {
    return permissions[flags.Caster_Details_Edit_SensorPointInRoller] ?? false
  }

  public static canEditSensorPointInNozzle (permissions: Permissions = {}): boolean {
    return permissions[flags.Caster_Details_Edit_SensorPointInNozzle] ?? false
  }

  public static canEditSensorPointInSegment (permissions: Permissions = {}): boolean {
    return permissions[flags.Caster_Details_Edit_SensorPointInSegment] ?? false
  }

  public static canEditDataPoint (permissions: Permissions = {}): boolean {
    return permissions[flags.Caster_Details_Edit_DataPoint] ?? false
  }

  public static canEditDataLine (permissions: Permissions = {}): boolean {
    return permissions[flags.Caster_Details_Edit_DataLine] ?? false
  }

  public static canEditNozzle (permissions: Permissions = {}): boolean {
    return permissions[flags.Caster_Details_Edit_Nozzle] ?? false
  }

  public static canEditSupportPoint (permissions: Permissions = {}): boolean {
    return permissions[flags.Caster_Details_Edit_SupportPoint] ?? false
  }

  public static canEditSegment (permissions: Permissions = {}): boolean {
    return permissions[flags.Caster_Details_Edit_Segment] ?? false
  }

  public static canSelectRoller (permissions: Permissions = {}): boolean {
    return permissions[flags.ThreeD_Caster_Select_Roller] ?? false
  }

  public static canSelectRollerBody (permissions: Permissions = {}): boolean {
    return permissions[flags.ThreeD_Caster_Select_RollerBody] ?? false
  }

  public static canSelectRollerBearing (permissions: Permissions = {}): boolean {
    return permissions[flags.ThreeD_Caster_Select_RollerBearing] ?? false
  }

  public static canSelectSensorPointInMoldFace (permissions: Permissions = {}): boolean {
    return permissions[flags.ThreeD_Caster_Select_SensorPointInMoldFace] ?? false
  }

  public static canSelectSensorPointInRollerBody (permissions: Permissions = {}): boolean {
    return permissions[flags.ThreeD_Caster_Select_SensorPointInRollerBody] ?? false
  }

  public static canSelectSensorPointInRollerBearing (permissions: Permissions = {}): boolean {
    return permissions[flags.ThreeD_Caster_Select_SensorPointInRollerBearing] ?? false
  }

  public static canSelectSensorPointInRoller (permissions: Permissions = {}): boolean {
    return permissions[flags.ThreeD_Caster_Select_SensorPointInRoller] ?? false
  }

  public static canSelectSensorPointInNozzle (permissions: Permissions = {}): boolean {
    return permissions[flags.ThreeD_Caster_Select_SensorPointInNozzle] ?? false
  }

  public static canSelectSensorPointInSegment (permissions: Permissions = {}): boolean {
    return permissions[flags.ThreeD_Caster_Select_SensorPointInSegment] ?? false
  }

  public static canSelectSupportPoint (permissions: Permissions = {}): boolean {
    return permissions[flags.ThreeD_Caster_Select_SupportPoint] ?? false
  }

  public static canSelectSegment (permissions: Permissions = {}): boolean {
    return permissions[flags.ThreeD_Caster_Select_Segment] ?? false
  }

  public static canSelectDataPoint (permissions: Permissions = {}): boolean {
    return permissions[flags.ThreeD_Caster_Select_DataPoint] ?? false
  }

  public static canSelectDataLine (permissions: Permissions = {}): boolean {
    return permissions[flags.ThreeD_Caster_Select_DataLine] ?? false
  }

  public static canSelectNozzle (permissions: Permissions = {}): boolean {
    return permissions[flags.ThreeD_Caster_Select_Nozzle] ?? false
  }

  public static canViewTypesAttributes (type: CasterElementNames | 'General', permissions: Permissions) {
    switch (type) {
      case 'General':
        return this.canViewMoldAttributes(permissions)
      case 'Roller':
        return this.canViewRollerAttributes(permissions)
      case 'RollerBearing':
        return this.canViewRollerBearingAttributes(permissions)
      case 'RollerBody':
        return this.canViewRollerBodyAttributes(permissions)
      case 'DataLine':
        return this.canViewDataLineAttributes(permissions)
      case 'DataPoint':
        return this.canViewDataPointAttributes(permissions)
      case 'Nozzle':
        return this.canViewNozzleAttributes(permissions)
      case 'SegmentGroup':
      case 'SegmentGroupSupportPoints':
      case 'SupportPoint':
        return this.canViewSupportPointAttributes(permissions)
      default:
        return false
    }
  }

  public static canViewMoldAttributes (permissions: Permissions = {}): boolean {
    return permissions[flags.Caster_Details_View_Mold] ?? false
  }

  public static canViewRollerAttributes (permissions: Permissions = {}): boolean {
    return permissions[flags.Caster_Details_View_Roller] ?? false
  }

  public static canViewRollerBodyAttributes (permissions: Permissions = {}): boolean {
    return permissions[flags.Caster_Details_View_RollerBody] ?? false
  }

  public static canViewRollerBearingAttributes (permissions: Permissions = {}): boolean {
    return permissions[flags.Caster_Details_View_RollerBearing] ?? false
  }

  public static canViewSensorPointAttributesInMoldFace (permissions: Permissions = {}): boolean {
    return permissions[flags.Caster_Details_View_SensorPointInMoldFace] ?? false
  }

  public static canViewSensorPointAttributesInRollerBody (permissions: Permissions = {}): boolean {
    return permissions[flags.Caster_Details_View_SensorPointInRollerBody] ?? false
  }

  public static canViewSensorPointAttributesInRollerBearing (permissions: Permissions = {}): boolean {
    return permissions[flags.Caster_Details_View_SensorPointInRollerBearing] ?? false
  }

  public static canViewSensorPointAttributesInRoller (permissions: Permissions = {}): boolean {
    return permissions[flags.Caster_Details_View_SensorPointInRoller] ?? false
  }

  public static canViewSensorPointAttributesInNozzle (permissions: Permissions = {}): boolean {
    return permissions[flags.Caster_Details_View_SensorPointInNozzle] ?? false
  }

  public static canViewSensorPointAttributesInSegment (permissions: Permissions = {}): boolean {
    return permissions[flags.Caster_Details_View_SensorPointInSegment] ?? false
  }

  public static canViewSupportPointAttributes (permissions: Permissions = {}): boolean {
    return permissions[flags.Caster_Details_View_SupportPoint] ?? false
  }

  public static canViewSensorPointAttributesInCurrentLocations (
    permissions: Permissions = {},
    paths: string[],
  ): boolean {
    const sensorPointViewPermissions = {
      MoldFace: FeatureFlags.canViewSensorPointAttributesInMoldFace(permissions),
      RollerBody: FeatureFlags.canViewSensorPointAttributesInRollerBody(permissions),
      RollerBearing: FeatureFlags.canViewSensorPointAttributesInRollerBearing(permissions),
      Roller: FeatureFlags.canViewSensorPointAttributesInRoller(permissions),
      Segment: FeatureFlags.canViewSensorPointAttributesInSegment(permissions),
      Nozzle: FeatureFlags.canViewSensorPointAttributesInNozzle(permissions),
    }
    const sensorPointLocations: any = FeatureFlagsUtil.getSensorPointLocations(paths)
    const sensorPointPermissions = Object.keys(sensorPointViewPermissions)

    for (const permission of sensorPointPermissions) {
      if (sensorPointLocations[permission] && !((sensorPointViewPermissions as any)[permission])) {
        return false
      }
    }

    return true
  }

  public static canViewDataPointAttributes (permissions: Permissions = {}): boolean {
    return permissions[flags.Caster_Details_View_DataPoint] ?? false
  }

  public static canViewDataLineAttributes (permissions: Permissions = {}): boolean {
    return permissions[flags.Caster_Details_View_DataLine] ?? false
  }

  public static canViewNozzleAttributes (permissions: Permissions = {}): boolean {
    return permissions[flags.Caster_Details_View_Nozzle] ?? false
  }

  public static canToggleBending (permissions: Permissions = {}): boolean {
    return permissions[flags.ThreeD_Buttons_Toggle_Bending] ?? false
  }

  public static canToggleSectionView (permissions: Permissions = {}): boolean {
    return permissions[flags.ThreeD_Buttons_Toggle_SectionView] ?? false
  }

  public static canToggleSectionSides (permissions: Permissions = {}): boolean {
    return permissions[flags.ThreeD_SectionView_ToggleSides] ?? false
  }

  public static canToggleRollerDetails (permissions: Permissions = {}): boolean {
    return permissions[flags.ThreeD_Buttons_Toggle_RollerDetails] ?? false
  }

  public static canCenter3DPosition (permissions: Permissions = {}): boolean {
    return permissions[flags.ThreeD_Buttons_CenterPosition] ?? false
  }

  public static canDeselectAll (permissions: Permissions = {}): boolean {
    return permissions[flags.ThreeD_Buttons_DeselectAll] ?? false
  }

  public static canDeleteFilterWith3DButton (permissions: Permissions = {}): boolean {
    return permissions[flags.ThreeD_Buttons_Toggle_Filter] ?? false
  }

  public static canViewSidesCube (permissions: Permissions = {}): boolean {
    return permissions[flags.ThreeD_SidesCube_View] ?? false
  }

  public static canToggleLiveMode (permissions: Permissions = {}): boolean {
    return permissions[flags.ThreeD_Buttons_Toggle_LiveMode] ?? false
  }

  public static canViewTreeView (permissions: Permissions = {}): boolean {
    return permissions[flags.Caster_TreeView_View] ?? false
  }

  public static canViewMoldMinWidth (permissions: Permissions = {}): boolean {
    return permissions[flags.ThreeD_SectionView_View_MoldMin] ?? false
  }

  public static canViewMoldMaxWidth (permissions: Permissions = {}): boolean {
    return permissions[flags.ThreeD_SectionView_View_MoldMax] ?? false
  }

  public static canSelectSomeElement (permissions: Permissions = {}): boolean {
    return (
      this.canSelectDataLine(permissions) ||
      this.canSelectDataPoint(permissions) ||
      this.canSelectNozzle(permissions) ||
      this.canSelectRoller(permissions) ||
      this.canSelectRollerBearing(permissions) ||
      this.canSelectRollerBody(permissions) ||
      this.canSelectSensorPointInMoldFace(permissions) ||
      this.canSelectSensorPointInNozzle(permissions) ||
      this.canSelectSensorPointInRoller(permissions) ||
      this.canSelectSensorPointInRollerBearing(permissions) ||
      this.canSelectSensorPointInRollerBody(permissions) ||
      this.canSelectSensorPointInSegment(permissions)
    )
  }

  public static canRenameElements (permissions: Permissions = {}): boolean {
    return permissions[flags.Caster_TreeView_Rename_Elements] ?? false
  }

  public static canJumpToElement (permissions: Permissions = {}): boolean {
    return permissions[flags.Caster_TreeView_JumpToElement] ?? false
  }

  public static canUseFilterBar (permissions: Permissions = {}): boolean {
    return permissions[flags.Caster_FilterBar_Use] ?? false
  }

  public static canViewFilterBar (permissions: Permissions = {}): boolean {
    return permissions[flags.Caster_FilterBar_View] ?? false
  }

  public static canFilterElements (permissions: Permissions = {}): boolean {
    return permissions[flags.FILTER_ELEMENTS] ?? false
  }

  public static canSetFilterAndJumpToElement (permissions: Permissions = {}): boolean {
    return permissions[flags.Caster_TreeView_SetFilterAndJumpToElement] ?? false
  }

  public static canJumpOverNozzles (permissions: Permissions = {}): boolean {
    return (
      (permissions[flags.ThreeD_SectionSlider_JumpOver_Nozzles] ?? false) &&
      (permissions[flags.ThreeD_Caster_Display_Nozzle] ?? false)
    )
  }

  public static canJumpOverRollers (permissions: Permissions = {}): boolean {
    return (
      (permissions[flags.ThreeD_SectionSlider_JumpOver_Roller] ?? false) &&
      (permissions[flags.ThreeD_Caster_Display_Roller] ?? false)
    )
  }

  public static canJumpOverDataPoints (permissions: Permissions = {}): boolean {
    return permissions[flags.ThreeD_SectionSlider_JumpOver_DataPoints] ?? false
  }

  public static canJumpOverSomeElement (permissions: Permissions = {}): boolean {
    return (
      this.canJumpOverDataPoints(permissions) ||
      this.canJumpOverNozzles(permissions) ||
      this.canJumpOverRollers(permissions)
    ) ?? false
  }

  public static canViewModules (permissions: Permissions = {}): boolean {
    return permissions[flags.Caster_Modules_View] ?? false
  }

  public static canFilterPerModule (permissions: Permissions = {}): boolean {
    return permissions[flags.Caster_Modules_Filter_Module] ?? false
  }

  public static canFilterPerModuleElementType (permissions: Permissions = {}): boolean {
    return permissions[flags.Caster_Modules_Filter_ModuleElementType] ?? false
  }

  public static canVerifyCaster (permissions: Permissions = {}): boolean {
    return permissions[flags.ProjectData__Verify_Caster] ?? false
  }

  public static canVerifyCatalogFile (permissions: Permissions = {}): boolean {
    return permissions[flags.ProjectData__Verify_NozzleCatalog] ?? false
  }

  public static canVerifyProcessParameter (permissions: Permissions = {}): boolean {
    return permissions[flags.ProjectData__Verify_ProcessParameters] ?? false
  }

  public static canStartSimulation (permissions: Permissions = {}): boolean {
    return permissions[flags.ProjectData__Start_Simulation] ?? false
  }

  public static canGenerateCasterFile (permissions: Permissions = {}): boolean {
    return permissions[flags.ProjectData__Use_GenerateCaster] ?? false
  }

  public static canUploadNozzleCatalog (permissions: Permissions = {}): boolean {
    return permissions[flags.ProjectData__Upload_NozzleCatalog] ?? false
  }

  public static canDownloadNozzleCatalog (permissions: Permissions = {}): boolean {
    return permissions[flags.ProjectData__Download_NozzleCatalog] ?? false
  }

  // TODO: after Menu_File_Use_DownloadCasterXML addition this isnt being used anymore
  public static canDownloadCasterXML (permissions: Permissions = {}): boolean {
    return permissions[flags.ProjectData__Download_CasterXML] ?? false
  }

  public static canCreateProject (permissions: Permissions = {}): boolean {
    return permissions[flags.ProjectData__Create_Project] ?? false
  }

  public static canDeleteProject (permissions: Permissions = {}): boolean {
    return permissions[flags.ProjectData__Delete_Project] ?? false
  }

  public static canRenameProject (permissions: Permissions = {}): boolean {
    return permissions[flags.ProjectData__Rename_Project] ?? false
  }

  public static canEditProjectDescription (permissions: Permissions = {}): boolean {
    return permissions[flags.ProjectData__Edit_ProjectDescription] ?? false
  }

  public static canCreateCase (permissions: Permissions = {}): boolean {
    return permissions[flags.ProjectData__Create_Case] ?? false
  }

  public static canDeleteCase (permissions: Permissions = {}): boolean {
    return permissions[flags.ProjectData__Delete_Case] ?? false
  }

  public static canRenameCase (permissions: Permissions = {}): boolean {
    return permissions[flags.ProjectData__Rename_Case] ?? false
  }

  public static canEditCaseDescription (permissions: Permissions = {}): boolean {
    return permissions[flags.ProjectData__Edit_CaseDescription] ?? false
  }

  public static canDeleteNozzleCatalog (permissions: Permissions = {}): boolean {
    return permissions[flags.ProjectData__Delete_NozzleCatalog] ?? false
  }

  public static canAddDynamicData (
    permissions: Permissions = {},
    visualizationMetaInformation: any,
    appState: AppState,
  ): boolean {
    return FeatureFlags.isDashboardEditable(visualizationMetaInformation, appState) &&
      (permissions[flags.CasterDashboard_Add_DynamicData] ?? false)
  }

  public static canEditDynamicData (
    permissions: Permissions = {},
    visualizationMetaInformation: any,
    appState: AppState,
  ): boolean {
    return FeatureFlags.isDashboardEditable(visualizationMetaInformation, appState) &&
      (permissions[flags.CasterDashboard_Edit_DynamicData] ?? false)
  }

  public static canMergeDynamicData (
    permissions: Permissions = {},
    visualizationMetaInformation: any,
    appState: AppState,
  ): boolean {
    return FeatureFlags.isDashboardEditable(visualizationMetaInformation, appState) &&
      (permissions[flags.CasterDashboard_Merge_DynamicData] ?? false)
  }

  public static canDeleteDynamicData (
    permissions: Permissions = {},
    visualizationMetaInformation: any,
    appState: AppState,
  ): boolean {
    return FeatureFlags.isDashboardEditable(visualizationMetaInformation, appState) &&
      (permissions[flags.CasterDashboard_Delete_DynamicData] ?? false)
  }

  public static canAddExternalData (
    permissions: Permissions = {},
    visualizationMetaInformation: any,
    appState: AppState,
  ): boolean {
    return FeatureFlags.isDashboardEditable(visualizationMetaInformation, appState) &&
      (permissions[flags.CasterDashboard_Add_ExternalData] ?? false)
  }

  public static canEditExternalData (
    permissions: Permissions = {},
    visualizationMetaInformation: any,
    appState: AppState,
  ): boolean {
    return FeatureFlags.isDashboardEditable(visualizationMetaInformation, appState) &&
      (permissions[flags.CasterDashboard_Edit_ExternalData] ?? false)
  }

  public static canMergeExternalData (
    permissions: Permissions = {},
    visualizationMetaInformation: any,
    appState: AppState,
  ): boolean {
    return FeatureFlags.isDashboardEditable(visualizationMetaInformation, appState) &&
      (permissions[flags.CasterDashboard_Merge_ExternalData] ?? false)
  }

  public static canDeleteExternalData (
    permissions: Permissions = {},
    visualizationMetaInformation: any,
    appState: AppState,
  ): boolean {
    return FeatureFlags.isDashboardEditable(visualizationMetaInformation, appState) &&
      (permissions[flags.CasterDashboard_Delete_ExternalData] ?? false)
  }

  public static canUseToggleEditMode (permissions: Permissions = {}): boolean {
    return permissions[flags.Menu_Edit_Use_ToggleEditMode] ?? false
  }

  public static canViewToggleEditMode (permissions: Permissions = {}): boolean {
    return permissions[flags.Menu_Edit_View_ToggleEditMode] ?? false
  }

  public static canUseExportPlots (permissions: Permissions = {}): boolean {
    return permissions[flags.CasterDashboard_Use_ExportPlots] ?? false
  }

  public static canViewExportPlots (permissions: Permissions = {}): boolean {
    return permissions[flags.CasterDashboard_View_ExportPlots] ?? false
  }

  public static canOpenCasterDataServerDialog (permissions: Permissions = {}): boolean {
    return permissions[flags.CasterDashboard_Open_CasterDataServerDialog] ?? false
  }

  public static canOpenCasterDateTimePickerDialog (permissions: Permissions = {}): boolean {
    return permissions[flags.CasterDashboard_Open_CasterDateTimePickerDialog] ?? false
  }

  public static canUseCommandFileMapping (permissions: Permissions = {}): boolean {
    return permissions[flags.Menu_Edit_Use_CommandFileMapping] ?? false
  }

  public static canViewCommandFileMapping (permissions: Permissions = {}): boolean {
    return permissions[flags.Menu_Edit_View_CommandFileMapping] ?? false
  }

  public static canWorkOnDynamicData (
    permissions: Permissions = {},
    visualizationMetaInformation: any,
    appState: AppState,
  ): boolean {
    if (!permissions || typeof permissions !== 'object') {
      return false
    }

    return (
      this.canAddDynamicData(permissions, visualizationMetaInformation, appState) ||
      this.canEditDynamicData(permissions, visualizationMetaInformation, appState) ||
      this.canDeleteDynamicData(permissions, visualizationMetaInformation, appState)
    )
  }

  public static canWorkOnMergedDynamicData (
    permissions: Permissions = {},
    visualizationMetaInformation: any,
    appState: AppState,
  ): boolean {
    if (!permissions || typeof permissions !== 'object') {
      return false
    }

    return (
      this.canMergeDynamicData(permissions, visualizationMetaInformation, appState) ||
      this.canEditDynamicData(permissions, visualizationMetaInformation, appState) ||
      this.canDeleteDynamicData(permissions, visualizationMetaInformation, appState)
    )
  }

  // After the addition of canViewSelectCasterDashboardMenuOption this is not used anymore
  public static canViewCasterDashboard (permissions: Permissions = {}): boolean {
    return permissions[flags.CasterDashboard_View] ?? false
  }

  public static canDownloadCasterDashboard (permissions: Permissions = {}): boolean {
    return permissions[flags.CasterDashboard_Download] ?? false
  }

  public static canCreateCasterDashboardConfig (permissions: Permissions = {}): boolean {
    return permissions[flags.CasterDashboard_Create_Config] ?? false
  }

  public static canEditCasterDashboardConfig (permissions: Permissions = {}): boolean {
    return permissions[flags.CasterDashboard_Edit_Config] ?? false
  }

  public static canDeleteCasterDashboardConfig (permissions: Permissions = {}): boolean {
    return permissions[flags.CasterDashboard_Delete_Config] ?? false
  }

  public static canAddPlotsCasterDashboard (
    permissions: Permissions = {},
    visualizationMetaInformation: any,
    appState: AppState,
  ): boolean {
    return FeatureFlags.isDashboardEditable(visualizationMetaInformation, appState) &&
      (permissions[flags.CasterDashboard_Add_Plot] ?? false)
  }

  public static canResizePlotsCasterDashboard (
    permissions: Permissions = {},
    visualizationMetaInformation: any,
    appState: AppState,
  ): boolean {
    return FeatureFlags.isDashboardEditable(visualizationMetaInformation, appState) &&
      (permissions[flags.CasterDashboard_Resize_Plot] ?? false)
  }

  public static canMovePlotsCasterDashboard (
    permissions: Permissions = {},
    visualizationMetaInformation: any,
    appState: AppState,
  ): boolean {
    return FeatureFlags.isDashboardEditable(visualizationMetaInformation, appState) &&
      (permissions[flags.CasterDashboard_Move_Plot] ?? false)
  }

  public static canEditPlotsCasterDashboard (
    permissions: Permissions = {},
    visualizationMetaInformation: any,
    appState: AppState,
  ): boolean {
    return FeatureFlags.isDashboardEditable(visualizationMetaInformation, appState) &&
      (permissions[flags.CasterDashboard_Edit_Plot] ?? false)
  }

  public static canDeletePlotsCasterDashboard (
    permissions: Permissions = {},
    visualizationMetaInformation: any,
    appState: AppState,
  ): boolean {
    return FeatureFlags.isDashboardEditable(visualizationMetaInformation, appState) &&
      (permissions[flags.CasterDashboard_Delete_Plot] ?? false)
  }

  public static canEditCasterDashboard (
    permissions: Permissions = {},
    visualizationMetaInformation: any,
    appState: AppState,
  ): boolean {
    return FeatureFlags.isDashboardEditable(visualizationMetaInformation, appState) &&
      (permissions[flags.CasterDashboard_Edit_Tab] ?? false)
  }

  public static canAddCasterDashboardTab (
    permissions: Permissions = {},
    visualizationMetaInformation: any,
    appState: AppState,
  ): boolean {
    return FeatureFlags.isDashboardEditable(visualizationMetaInformation, appState) &&
      (permissions[flags.CasterDashboard_Add_Tab] ?? false)
  }

  public static canDeleteCasterDashboardTab (
    permissions: Permissions = {},
    visualizationMetaInformation: any,
    appState: AppState,
  ): boolean {
    return FeatureFlags.isDashboardEditable(visualizationMetaInformation, appState) &&
      (permissions[flags.CasterDashboard_Delete_Tab] ?? false)
  }

  public static canUploadCasterDashboardConfig (permissions: Permissions = {}): boolean {
    return permissions[flags.CasterDashboard_Upload_Config] ?? false
  }

  public static canViewParameterDashboard (permissions: Permissions = {}): boolean {
    return permissions[flags.ParameterDashboard_View] ?? false
  }

  public static canAddPlotsParameterDashboard (permissions: Permissions = {}): boolean {
    return permissions[flags.ParameterDashboard_Add_Plot] ?? false
  }

  public static canDownloadParameterDashboard (permissions: Permissions = {}): boolean {
    return permissions[flags.ParameterDashboard_Download] ?? false
  }

  public static canDeleteParameterDashboardConfig (permissions: Permissions = {}): boolean {
    return permissions[flags.ParameterDashboard_Delete_Config] ?? false
  }

  public static canCreateParameterDashboardConfig (permissions: Permissions = {}): boolean {
    return permissions[flags.ParameterDashboard_Create_Config] ?? false
  }

  public static canMovePlotsParameterDashboard (permissions: Permissions = {}): boolean {
    return permissions[flags.ParameterDashboard_Move_Plot] ?? false
  }

  public static canResizePlotsParameterDashboard (permissions: Permissions = {}): boolean {
    return permissions[flags.ParameterDashboard_Resize_Plot] ?? false
  }

  public static canEditPlotsParameterDashboard (permissions: Permissions = {}): boolean {
    return permissions[flags.ParameterDashboard_Edit_Plot] ?? false
  }

  public static canEditParameterDashboard (permissions: Permissions = {}): boolean {
    return permissions[flags.ParameterDashboard_Edit] ?? false
  }

  public static canAddParameterDashboardTab (permissions: Permissions = {}): boolean {
    return permissions[flags.ParameterDashboard_Add_Tab] ?? false
  }

  public static canDeleteParameterDashboardTab (permissions: Permissions = {}): boolean {
    return permissions[flags.ParameterDashboard_Delete_Tab] ?? false
  }

  public static canUploadParameterDashboardConfig (permissions: Permissions = {}): boolean {
    return permissions[flags.ParameterDashboard_Upload_Config] ?? false
  }

  public static canDeletePlotsParameterDashboard (permissions: Permissions = {}): boolean {
    return permissions[flags.ParameterDashboard_Delete_Plot] ?? false
  }

  public static canUploadExecutableFile (permissions: Permissions = {}): boolean {
    return permissions[flags.Dialog_Run_UploadFile] ?? false
  }

  public static canEditExecutableDialog (permissions: Permissions = {}): boolean {
    return permissions[flags.Dialog_Run_Edit] ?? false
  }

  public static canViewResultDashboard (permissions: Permissions = {}): boolean {
    return permissions[flags.ResultDashboard_View] ?? false
  }

  public static canAddPlotsResultDashboard (permissions: Permissions = {}): boolean {
    return permissions[flags.ResultDashboard_Add_Plot] ?? false
  }

  public static canDownloadResultDashboard (permissions: Permissions = {}): boolean {
    return permissions[flags.ResultDashboard_Download] ?? false
  }

  public static canDeleteResultDashboardConfig (permissions: Permissions = {}): boolean {
    return permissions[flags.ResultDashboard_Delete_Config] ?? false
  }

  public static canCreateResultDashboardConfig (permissions: Permissions = {}): boolean {
    return permissions[flags.ResultDashboard_Create_Config] ?? false
  }

  public static canMovePlotsResultDashboard (permissions: Permissions = {}): boolean {
    return permissions[flags.ResultDashboard_Move_Plot] ?? false
  }

  public static canResizePlotsResultDashboard (permissions: Permissions = {}): boolean {
    return permissions[flags.ResultDashboard_Resize_Plot] ?? false
  }

  public static canEditPlotsResultDashboard (permissions: Permissions = {}): boolean {
    return permissions[flags.ResultDashboard_Edit_Plot] ?? false
  }

  public static canEditResultDashboard (permissions: Permissions = {}): boolean {
    return permissions[flags.ResultDashboard_Edit] ?? false
  }

  public static canAddResultDashboardTab (permissions: Permissions = {}): boolean {
    return permissions[flags.ResultDashboard_Add_Tab] ?? false
  }

  public static canDeleteResultDashboardTab (permissions: Permissions = {}): boolean {
    return permissions[flags.ResultDashboard_Delete_Tab] ?? false
  }

  public static canUploadResultDashboardConfig (permissions: Permissions = {}): boolean {
    return permissions[flags.ResultDashboard_Upload_Config] ?? false
  }

  public static canDeletePlotsResultDashboard (permissions: Permissions = {}): boolean {
    return permissions[flags.ResultDashboard_Delete_Plot] ?? false
  }

  public static canDeleteTabInCurrentDashboardType (
    permissions: Permissions = {},
    visualizationMetaInformation: any,
    appState: AppState,
  ) {
    return (
      (
        appState === AppState.Caster &&
        FeatureFlags.canDeleteCasterDashboardTab(permissions, visualizationMetaInformation, appState)
      ) ||
      (appState === AppState.ParamDashboard && FeatureFlags.canDeleteParameterDashboardTab(permissions)) ||
      (appState === AppState.ResultDashboard && FeatureFlags.canDeleteResultDashboardTab(permissions))
    )
  }

  public static canEditDashboardOptionsInCurrentDashboardType (
    permissions: Permissions = {},
    visualizationMetaInformation: any,
    appState: AppState,
  ) {
    return (
      (
        appState === AppState.Caster &&
        FeatureFlags.canEditCasterDashboard(permissions, visualizationMetaInformation, appState)
      ) ||
      (appState === AppState.ParamDashboard && FeatureFlags.canEditParameterDashboard(permissions)) ||
      (appState === AppState.ResultDashboard && FeatureFlags.canEditResultDashboard(permissions))
    )
  }

  public static canMovePlotsInCurrentDashboardType (
    permissions: Permissions = {},
    visualizationMetaInformation: any,
    appState: AppState,
  ) {
    return (
      (
        appState === AppState.Caster &&
        FeatureFlags.canMovePlotsCasterDashboard(permissions, visualizationMetaInformation, appState)
      ) ||
      (appState === AppState.ParamDashboard && FeatureFlags.canMovePlotsParameterDashboard(permissions)) ||
      (appState === AppState.ResultDashboard && FeatureFlags.canMovePlotsResultDashboard(permissions))
    )
  }

  public static canDownloadPlotImage (permission: Permissions = {}) {
    return permission[flags.CasterDashboard_Download_Plot] ?? false
  }

  public static canFullSizePlot (permission: Permissions = {}) {
    return permission[flags.CasterDashboard_FullSize_Plot] ?? false
  }

  public static canResizePlotsInCurrentDashboardType (
    permissions: Permissions = {},
    visualizationMetaInformation: any,
    appState: AppState,
  ) {
    return (
      (
        appState === AppState.Caster &&
        FeatureFlags.canResizePlotsCasterDashboard(permissions, visualizationMetaInformation, appState)
      ) ||
      (appState === AppState.ParamDashboard && FeatureFlags.canResizePlotsParameterDashboard(permissions)) ||
      (appState === AppState.ResultDashboard && FeatureFlags.canResizePlotsResultDashboard(permissions))
    )
  }

  public static canAddPlotsInCurrentDashboardType (
    permissions: Permissions = {},
    visualizationMetaInformation: any,
    appState: AppState,
  ) {
    return (
      (
        appState === AppState.Caster &&
        FeatureFlags.canAddPlotsCasterDashboard(permissions, visualizationMetaInformation, appState)
      ) ||
      (appState === AppState.ParamDashboard && FeatureFlags.canAddPlotsParameterDashboard(permissions)) ||
      (appState === AppState.ResultDashboard && FeatureFlags.canAddPlotsParameterDashboard(permissions))
    )
  }

  public static canDeletePlotsInCurrentDashboardType (
    permissions: Permissions = {},
    visualizationMetaInformation: any,
    appState: AppState,
  ) {
    return (
      (
        appState === AppState.Caster &&
        FeatureFlags.canDeletePlotsCasterDashboard(permissions, visualizationMetaInformation, appState)
      ) ||
      (appState === AppState.ParamDashboard && FeatureFlags.canDeletePlotsParameterDashboard(permissions)) ||
      (appState === AppState.ResultDashboard && FeatureFlags.canDeletePlotsResultDashboard(permissions))
    )
  }

  public static canEditPlotsInCurrentDashboardType (
    permissions: Permissions = {},
    visualizationMetaInformation: any,
    appState: AppState,
  ) {
    return (
      (
        appState === AppState.Caster &&
        FeatureFlags.canEditPlotsCasterDashboard(permissions, visualizationMetaInformation, appState)
      ) ||
      (appState === AppState.ParamDashboard && FeatureFlags.canEditPlotsParameterDashboard(permissions)) ||
      (appState === AppState.ResultDashboard && FeatureFlags.canEditPlotsResultDashboard(permissions))
    )
  }

  public static hasPermission (
    requirePermissions: Array<string> | string,
    permissions: Permissions = {},
  ): boolean {
    const authorizations = requirePermissions instanceof Array ? requirePermissions : [ requirePermissions ]

    return authorizations.reduce(
      (acc: boolean, authorization: string) => acc && Boolean(permissions[authorization]),
      true,
    )
  }

  public static canViewSendToConsistencyCheckOption (permissions: Permissions = {}): boolean {
    return Boolean(permissions[flags.Menu_File_View_SendCasterToConsistencyCheck])
  }

  public static canUseSendToConsistencyCheckOption (permissions: Permissions = {}): boolean {
    return Boolean(permissions[flags.Menu_File_Use_SendCasterToConsistencyCheck])
  }

  public static canViewCaster (permissions: Permissions = {}): boolean {
    return permissions[flags.Caster__View] ?? false
  }

  public static usesSlimVersion (permissions: Permissions = {}): boolean {
    return permissions[flags.SlimVersion] ?? false
  }

  // Vis
  public static canEditVisualization (permissions: Permissions = {}): boolean {
    return Boolean(
      permissions[flags.ResultDashboard_Upload_Config] &&
        permissions[flags.ResultDashboard_Download] &&
        permissions[flags.ResultDashboard_Create_Config] &&
        permissions[flags.ResultDashboard_View],
    )
  }

  public static canResizeCasterDashboards (
    permissions: Permissions = {},
    visualizationMetaInformation: any,
    appState: AppState,
  ): boolean {
    return FeatureFlags.isDashboardEditable(visualizationMetaInformation, appState) &&
      (Boolean(permissions[flags.CasterDashboard_Resize]))
  }

  public static canEditCasterComparison (
    permissions: Permissions = {},
    visualizationMetaInformation: any,
    appState: AppState,
  ): boolean {
    return FeatureFlags.isDashboardEditable(visualizationMetaInformation, appState) &&
      (Boolean(permissions[flags.CasterDashboard_Edit_CasterComparison]))
  }

  public static canCompareCastersInCasterDialog (permissions: Permissions = {}): boolean {
    return Boolean(permissions[flags.Caster_Details_Compare])
  }

  public static canViewCasterComparison (permissions: Permissions = {}): boolean {
    return Boolean(permissions[flags.CasterDashboard_View_CasterComparison])
  }

  public static canActivateFilterPlot (permissions: Permissions = {}): boolean {
    return Boolean(permissions[flags.CasterDashboard_Filter_Plot])
  }

  public static canEditCriticalStrainCurve (permissions: Permissions = {}): boolean {
    return Boolean(permissions[flags.CasterDashboard_Edit_CriticalStrainCurve])
  }

  public static canUseFilterControls (permissions: Permissions = {}): boolean {
    return Boolean(permissions[flags.ThreeD_FilterControls_Use])
  }

  // lockedBy
  public static getLockedStatus (
    type: string,
    authenticationData: AuthData,
    caseLocks: CaseLock[],
  ): { isLocked: boolean } {
    let isLocked = false

    switch (type) {
      case 'General':
      case 'SegmentGroup':
      case 'Segment':
      case 'SupportPoint':
        {
          const miscLock = caseLocks.find((lock: CaseLock) => lock.type === 'misc')

          isLocked = Boolean(miscLock) && miscLock?.user?.id !== authenticationData?.id
        }

        break
      case 'Nozzle':
        {
          const nozzleLock = caseLocks.find((lock: CaseLock) => lock.type === 'nozzle')

          isLocked = Boolean(nozzleLock) && nozzleLock?.user?.id !== authenticationData?.id
        }

        break
      case 'Roller':
      case 'RollerBody':
      case 'RollerBearing':
        {
          const rollerLock = caseLocks.find((lock: CaseLock) => lock.type === 'roller')

          isLocked = Boolean(rollerLock) && rollerLock?.user?.id !== authenticationData?.id
        }

        break
      default:
        break
    }

    return { isLocked }
  }

  public static canViewOpenProjectMenuOption (permissions: Permissions = {}): boolean {
    return Boolean(permissions[flags.Menu_File_View_OpenProject])
  }

  public static canUseOpenProjectMenuOption (permissions: Permissions = {}): boolean {
    return Boolean(permissions[flags.Menu_File_Use_OpenProject])
  }

  public static canViewCreateProjectMenuOption (permissions: Permissions = {}): boolean {
    return Boolean(permissions[flags.Menu_File_View_CreateProject])
  }

  public static canUseCreateProjectMenuOption (permissions: Permissions = {}): boolean {
    return Boolean(permissions[flags.Menu_File_Use_CreateProject])
  }

  public static canViewUploadCasterXMLMenuOption (permissions: Permissions = {}): boolean {
    return Boolean(permissions[flags.Menu_File_View_UploadCasterXML])
  }

  public static canUseUploadCasterXMLMenuOption (permissions: Permissions = {}): boolean {
    return Boolean(permissions[flags.Menu_File_Use_UploadCasterXML])
  }

  public static canViewUploadNozzleCatalogMenuOption (permissions: Permissions = {}): boolean {
    return Boolean(permissions[flags.Menu_File_View_UploadNozzleCatalog])
  }

  public static canUseUploadNozzleCatalogMenuOption (permissions: Permissions = {}): boolean {
    return Boolean(permissions[flags.Menu_File_Use_UploadNozzleCatalog])
  }

  public static canViewDownloadCasterXMLMenuOption (permissions: Permissions = {}): boolean {
    return Boolean(permissions[flags.Menu_File_View_DownloadCasterXML])
  }

  public static canUseDownloadCasterXMLMenuOption (permissions: Permissions = {}): boolean {
    return Boolean(permissions[flags.Menu_File_Use_DownloadCasterXML])
  }

  public static canViewLoadCasterMenuOption (permissions: Permissions = {}): boolean {
    return Boolean(permissions[flags.Menu_File_View_LoadCasterView])
  }

  public static canUseLoadCasterMenuOption (permissions: Permissions = {}): boolean {
    return Boolean(permissions[flags.Menu_File_Use_LoadCasterView])
  }

  public static canViewSelectCasterDashboardMenuOption (permissions: Permissions = {}): boolean {
    return Boolean(permissions[flags.Menu_File_View_SelectCasterDashboard])
  }

  public static canUseSelectCasterDashboardMenuOption (permissions: Permissions = {}): boolean {
    return Boolean(permissions[flags.Menu_File_Use_SelectCasterDashboard])
  }

  public static canViewManageDynamicDataMenuOption (permissions: Permissions = {}): boolean {
    return Boolean(permissions[flags.Menu_Edit_View_ManageDynamicData])
  }

  public static canUseManageDynamicDataMenuOption (
    permissions: Permissions = {},
    visualizationMetaInformation: any,
    appState: AppState,
  ): boolean {
    return FeatureFlags.isDashboardEditable(visualizationMetaInformation, appState) &&
      (Boolean(permissions[flags.Menu_Edit_Use_ManageDynamicData]))
  }

  public static canViewToggleFullScreenMenuOption (permissions: Permissions = {}): boolean {
    return Boolean(permissions[flags.Menu_View_View_ToggleFullScreen])
  }

  public static canUseToggleFullScreenMenuOption (permissions: Permissions = {}): boolean {
    return Boolean(permissions[flags.Menu_View_Use_ToggleFullScreen])
  }

  public static canViewExitFullScreenMenuOption (permissions: Permissions = {}): boolean {
    return Boolean(permissions[flags.Menu_View_View_ExitFullScreen])
  }

  public static canUseExitFullScreenMenuOption (permissions: Permissions = {}): boolean {
    return Boolean(permissions[flags.Menu_View_Use_ExitFullScreen])
  }

  public static canViewRecreate3DMenuOption (permissions: Permissions = {}): boolean {
    return Boolean(permissions[flags.Menu_View_View_Recreate3D])
  }

  public static canUseRecreate3DMenuOption (permissions: Permissions = {}): boolean {
    return Boolean(permissions[flags.Menu_View_Use_Recreate3D])
  }

  public static canUseConfigurePlotsMenuOption (
    permissions: Permissions = {},
    visualizationMetaInformation: any,
    appState: AppState,
  ): boolean {
    return FeatureFlags.isDashboardEditable(visualizationMetaInformation, appState) &&
      (Boolean(permissions[flags.Menu_Edit_Use_ConfigurePlots]))
  }

  public static canViewConfigurePlotsMenuOption (permissions: Permissions = {}): boolean {
    return Boolean(permissions[flags.Menu_Edit_View_ConfigurePlots])
  }

  public static canViewModulesMenuOption (permissions: Permissions = {}): boolean {
    return Boolean(permissions[flags.Menu_Modules_View])
  }

  public static canUseModulesMenuOption (permissions: Permissions = {}): boolean {
    return Boolean(permissions[flags.Menu_Modules_Use])
  }

  public static isDashboardEditable (visualizationMetaInformation: any, appState: AppState) {
    const { config } = visualizationMetaInformation?.[appState] ?? {}

    return !(config ?? '').startsWith('group-')
  }

  public static canHideUser (permissions: Permissions = {}): boolean {
    return Boolean(permissions[flags.OpenProject__Use_HideUser])
  }

  public static canShare (permissions: Permissions = {}): boolean {
    return Boolean(permissions[flags.OpenProject__Use_Share])
  }

  public static canViewFullyResetShimMenuOption (permissions: Permissions = {}): boolean {
    return Boolean(permissions[flags.Menu_Edit_View_FullyResetShim])
  }

  public static canUseFullyResetShimMenuOption (permissions: Permissions = {}): boolean {
    return Boolean(permissions[flags.Menu_Edit_Use_FullyResetShim])
  }

  public static canViewEditExternalDataSourcesMenuOption (permissions: Permissions = {}): boolean {
    return Boolean(permissions[flags.Menu_Edit_View_ExternalDataSources])
  }

  public static canUseEditExternalDataSourcesMenuOption (permissions: Permissions = {}): boolean {
    return Boolean(permissions[flags.Menu_Edit_Use_ExternalDataSources])
  }

  public static canViewCreateRealDataCaseMenuOption (permissions: Permissions = {}): boolean {
    return Boolean(permissions[flags.Menu_Edit_View_CreateRealDataCase])
  }

  public static canUseCreateRealDataCaseMenuOption (permissions: Permissions = {}): boolean {
    return Boolean(permissions[flags.Menu_Edit_Use_CreateRealDataCase])
  }

  public static canViewShareStateMenuOption (permissions: Permissions = {}): boolean {
    return Boolean(permissions[flags.Menu_Edit_View_ShareState])
  }

  public static canUseShareStateMenuOption (permissions: Permissions = {}): boolean {
    return Boolean(permissions[flags.Menu_Edit_Use_ShareState])
  }

  public static canViewCreateRealDataContextMenuOption (permissions: Permissions = {}): boolean {
    return Boolean(permissions[flags.Caster_TreeView_ContextMenu_View_CreateRealData])
  }

  public static canUseCreateRealDataContextMenuOption (permissions: Permissions = {}): boolean {
    return Boolean(permissions[flags.Caster_TreeView_ContextMenu_Use_CreateRealData])
  }

  public static canViewAndUseSelectionForRealData (permissions: Permissions = {}): boolean {
    return Boolean(permissions[flags.Caster_TreeView_ViewAndUse_SelectionForRealData])
  }

  public static canViewAndUseHistoryDialogButton (permissions: Permissions = {}): boolean {
    return Boolean(permissions[flags.Caster_TreeView_ViewAndUse_HistoryDialogButton])
  }

  public static canViewRealDataBadges (permissions: Permissions = {}): boolean {
    return Boolean(permissions[flags.RealData__View_Badges])
  }

  public static canViewPartsWarehouse (permissions: Permissions = {}): boolean {
    return Boolean(permissions[flags.Caster_PartsWarehouse_View])
  }

  public static canDownloadUploadedFileInExecutablesDialog (permissions: Permissions = {}): boolean {
    return Boolean(permissions[flags.ExecutablesDialog_ViewAndUse_DownloadUploadedFile])
  }

  public static canViewStateSlider (permissions: Permissions = {}): boolean {
    return Boolean(permissions[flags.ThreeD_Caster_Use_StateSlider])
  }
}
