import hotkeys from 'hotkeys-js'
import Plotly from 'plotly.js-dist-min'
import { Component } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { compose } from 'redux'
import styled from 'styled-components'
import { v4 as uuid } from 'uuid'

import BaseDialog from '@/react/dialogs/BaseDialog'
import { type ResizeData, ResizeDetector } from '@/react/ResizeDetector'
import * as ApplicationActions from '@/store/application/main/actions'
import { updateElement } from '@/store/elements/actions'
import { getElementMapsObject } from '@/store/elements/logic'
import type { DefaultState } from '@/types/state'
import type { Translation } from '@/types/translation'
import type { PlotConfig } from '@/types/visualization'
import { Identifiable } from '@/Util/decorators/Identifiable'

import ViewTile from '../ViewTile'

const Container = styled(ResizeDetector)`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding-bottom: 10px;
`

const connector = connect((state: DefaultState) => ({
  plotConfigs: state.visualization.plotConfigs,
  params: state.application.main.params,
  currentSimulationCase: state.application.main.currentSimulationCase,
  ...getElementMapsObject(state),
}), {
  closeDialog: ApplicationActions.closeDialog,
  updateElement,
})

type PropsFromRedux = ConnectedProps<typeof connector>

interface Props extends PropsFromRedux {
  t: Translation
}

type State = {
  plotConfig: PlotConfig | null
}

export class FullSizedPlotDialog extends Component<Props, State> {
  @Identifiable('FullSizedPlotDialog') public static readonly NAME: string

  private readonly additionalId = uuid()

  public override state: State = {
    plotConfig: null,
  }

  public override componentDidMount () {
    const { plotConfigs, params } = this.props
    const plotConfig = plotConfigs[params.plotConfigId]

    if (!plotConfig) {
      return
    }

    this.setState({ plotConfig })

    hotkeys('Escape', this.handleClose)
  }

  public override componentWillUnmount () {
    hotkeys.deleteScope('other')
    hotkeys.unbind('Escape', this.handleClose)
  }

  private readonly handleClose = () => {
    this.props.closeDialog(FullSizedPlotDialog.NAME)
  }

  private readonly handleResize = ({ width, height }: ResizeData) => {
    const { params: { tileId } } = this.props
    const plot: any = document.getElementById(`plot_${tileId}_${this.additionalId}`)

    if (!plot?.layout) {
      return
    }

    requestAnimationFrame(() => {
      try {
        Plotly.relayout(plot, { ...plot.layout, width, height })
      }
      catch (e) {}
    })
  }

  public override render () {
    const { params } = this.props

    return (
      <BaseDialog
        title='Plot View'
        icon='pe-7s-graph1'
        header='Plot View'
        onClose={this.handleClose}
        contentMinHeight='calc(90vh - 100px)'
        fullWidth
      >
        <Container onResize={(data) => this.handleResize(data)}>
          <ViewTile tileId={params.tileId} additionalId={this.additionalId} viewId={null} viewOnly={false} />
        </Container>
      </BaseDialog>
    )
  }
}

export default compose<any>(connector)(FullSizedPlotDialog)
